import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["statusFilter", "search", "searchInput"];

  allStatusFilters = undefined;

  disconnect() {
    this.collapseSearch();
  }

  collapseSearch() {
    this.searchInputTarget.value = "";
    this.searchTarget.ariaExpanded = false;
    this.restoreAllFilterTargets();
  }

  expandSearch() {
    this.searchTarget.ariaExpanded = true;
    this.searchInputTarget.focus();
    this.hideUnselectedFilterTargets();
  }

  hideUnselectedFilterTargets() {
    if (this.allStatusFilters) return;

    this.allStatusFilters = [...this.statusFilterTargets];
    this.statusFilterTargets.filter((target) => !this.isSelected(target)).forEach((target) => target.remove());
  }

  isSelected(target) {
    return target.querySelector("input[type=radio]:checked");
  }

  restoreAllFilterTargets() {
    if (!this.allStatusFilters) return;

    const parent = this.statusFilterTarget.parentElement;
    this.statusFilterTarget.remove();
    this.allStatusFilters.forEach((target) => parent.appendChild(target));
    this.allStatusFilters = undefined;
  }
}
