/* eslint-env browser */
/* global PayFields, jQuery */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tokenResponseInput", "errorList"];

  connect() {
    PayFields.jQuery = jQuery.noConflict(true);
    this.element.setAttribute("data-action", "submit->payrix--payfields#submitForm:prevent");

    PayFields.onSuccess = (response) => {
      this.tokenResponseInputTarget.value = JSON.stringify(response);
      this.element.submit();
    };

    PayFields.onFailure = this.handleError;
  }

  submitForm() {
    PayFields.submit();
  }

  handleError(response) {
    this.showValidationErrors(response.errors);
  }

  showValidationErrors(errors) {
    this.errorListTarget.clear();

    errors.forEach((error) => {
      if (error.field && !error.field.match("^payment.")) {
        const li = document.createElement("li");
        li.textContent = error.msg;
        this.errorListTarget.appendChild(li);
      }
    });
  }
}
