/* global Turbo */

import { Controller } from "stimulus";
import useEventController from "../../../frontend/composables/use_event_controller";

export default class extends Controller {
  static targets = ["checkbox", "allCheckbox", "offInput"];

  initialize() {
    useEventController(this);
  }

  connect() {
    this.updateStates();
  }

  allCheckboxTargetConnected(checkbox) {
    this.configureClickHandler(checkbox);
  }

  checkboxTargetConnected(checkbox) {
    this.configureClickHandler(checkbox);
  }

  configureClickHandler(checkbox) {
    this.listenOn(checkbox.closest("[role=menuitem]"), "click", (event) =>
      this.toggle(event, checkbox, !checkbox.checked),
    );
    this.listenOn(checkbox, "click", (event) => this.toggle(event, checkbox, checkbox.checked));
  }

  toggle(event, checkbox, checked) {
    event.stopPropagation();
    checkbox.checked = checked;
    this.updateStates(checkbox);
  }

  configureMenuItem() {
    this.checkboxTargets.forEach((checkbox) => {
      const offInput = checkbox.parentElement.querySelector("[role=offInput]");
      if (checkbox.checked) {
        offInput?.setAttribute("disabled", "disabled");
      } else {
        offInput?.removeAttribute("disabled");
      }

      const menuItem = checkbox.closest("[role=menuitem]");
      menuItem?.setAttribute("aria-checked", checkbox.checked);
    });
  }

  updateStates(checkbox = null) {
    if (checkbox === this.allCheckboxTarget) {
      this.updateAllInputState();
    } else {
      this.updateInputStates();
    }
    this.configureMenuItem();
  }

  updateInputStates() {
    if (this.hasAllCheckboxTarget) {
      let allChecked = true;
      this.checkboxTargets.forEach((element) => {
        allChecked = allChecked && element.checked;
      });
      this.allCheckboxTarget.checked = allChecked;
    }
  }

  updateAllInputState() {
    if (this.hasAllCheckboxTarget) {
      const allChecked = this.allCheckboxTarget.checked;
      this.checkboxTargets.forEach((element) => {
        element.checked = allChecked;
      });
    }
  }
}
