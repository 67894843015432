/* global _ */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cardLast4", "city", "country", "line1", "line2", "postalCode", "state"];

  async fetch(paymentMethodId) {
    const response = await fetch(`/accounts/payment_methods/${paymentMethodId}`);

    if (!response.ok) {
      return;
    }

    const paymentMethod = await response.json();

    Object.keys(paymentMethod.address || {}).forEach((key) => {
      this[`${_.camelCase(key)}Target`].textContent = paymentMethod.address[key];
    });

    this.cardLast4Target.innerText = paymentMethod.card.last4;
  }
}
