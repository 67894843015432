/* eslint-env browser */

import { Controller } from "stimulus";

export default class extends Controller {
  openModal(event) {
    const modalElement = document.querySelector(event.params.modal);

    modalElement?.dispatchEvent?.(new CustomEvent("open"));
  }
}
