/* global document, window */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "pane"];

  static values = { defaultTab: String };

  connect() {
    const selectedTab = this.tabFromId(this.defaultTabValue) || this.tabTargets[0];
    this.activateTab(selectedTab);
  }

  paneTargetConnected(pane) {
    pane.classList.add("hidden");
  }

  tabTargetConnected(tab) {
    tab.setAttribute("aria-selected", "false");
  }

  select(event) {
    this.activateTab(event.currentTarget);
    this.updateLocation(event.currentTarget);
  }

  paneFromId(id) {
    return this.paneTargets.find((element) => element.id === id);
  }

  tabFromId(id) {
    return this.tabTargets.find((element) => element.id === id);
  }

  activateTab(tab) {
    const selectedPane = this.paneFromId(tab.dataset.tabPaneId);
    this.element.dataset.selectedTabId = tab.id;
    if (selectedPane.classList.contains("hidden")) {
      this.paneTargets.forEach((element) => {
        element.classList.add("hidden");
      });
      selectedPane.classList.remove("hidden");

      this.tabTargets.forEach((element) => {
        element.setAttribute("aria-selected", "false");
      });
      tab.setAttribute("aria-selected", "true");
      document.activeElement.blur();
    }
  }

  updateLocation(selectedTab) {
    const { tabQueryId } = selectedTab.dataset;
    if (tabQueryId) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("tab", tabQueryId);
      window.history.replaceState(null, null, `?${queryParams.toString()}`);
    }
  }
}
