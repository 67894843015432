/* eslint-env browser */

export async function fetchRequest(url, { body = {}, headers = {}, method = "GET" } = {}) {
  const params = {
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")?.content,
      ...headers,
    },
    body: JSON.stringify(body),
    method: method.toUpperCase(),
  };

  if (params.method === "GET") {
    delete params.body;
  }

  return fetch(url, params);
}

export const fetchStream = async (url, { body = {}, headers = {}, method = "GET" } = {}) =>
  fetchRequest(url, {
    headers: { ...headers, Accept: "text/vnd.turbo-stream.html" },
    method,
    body,
  });
