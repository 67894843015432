import { Controller } from "stimulus";
import useEventController from "../../../composables/use_event_controller";

export default class extends Controller {
  static targets = ["checkbox"];

  initialize() {
    useEventController(this);
  }

  checkboxTargetConnected(checkbox) {
    this.wellSelected(checkbox);
    this.listenOn(checkbox, "change", (event) => this.wellSelected(event.target));
  }

  checkboxTargetDisconnected(checkbox) {
    this.listenOff(checkbox, "change");
  }

  wellSelected(checkbox) {
    const well = checkbox.closest("[role=well]");
    well.classList.toggle("!bg-white", checkbox.checked);
  }
}
