/* eslint-env browser */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["orderstatus", "orderstatusField"];

  orderstatusFieldTargetConnected() {
    setTimeout(() => this.updateOrderstatusButtonLabel());
  }

  orderstatusUpdated() {
    const selectedString = this.selectedOrderstatuses.map((orderstatus) => orderstatus.value).join(".");

    if (selectedString !== "" && selectedString !== null) {
      const url = new URL(window.location);
      url.searchParams.set("filters[orderstatus_ids]", selectedString);
      window.history.pushState({}, null, url);
    }

    this.orderstatusFieldTarget.value = selectedString;
    this.orderstatusFieldTarget.dispatchEvent(new Event("change"));

    this.updateOrderstatusButtonLabel();
  }

  toggleOrderStatuses(event) {
    const { enable } = event.params;

    this.orderstatusTargets.forEach((element) => {
      element.checked = enable;
    });

    this.orderstatusUpdated();
  }

  updateOrderstatusButtonLabel() {
    const isDefaultLabel = this.selectedOrderstatuses.length === this.orderstatusTargets.length;
    const label = isDefaultLabel
      ? "All order statuses"
      : `${this.selectedOrderstatuses.length} order statuses selected`;

    window.dispatchEvent(
      new CustomEvent("dropdown:update", {
        target: this.element,
        detail: { label, isDefaultLabel },
      }),
    );
  }

  get selectedOrderstatuses() {
    return this.orderstatusTargets.filter((orderstatus) => orderstatus.checked);
  }
}
