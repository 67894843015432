/* eslint-env browser */
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["body", "subject", "template"];

  static values = { redirect: String };

  async templateSelected(event) {
    if (event.target.value === "redirect") {
      window.location.href = this.redirectValue;
      return;
    }

    if (event.target.value === "") {
      this.bodyTarget.value = "";

      if (this.hasSubjectTarget) this.subjectTarget.value = "";

      return;
    }

    const response = await fetch(`/email_templates/${event.target.value}`, {
      headers: { "Content-Type": "application/json" },
    });

    const body = await response.json();

    this.bodyTarget.value = body.message;
    this.bodyTarget.dispatchEvent(new Event("input"));

    if (this.hasSubjectTarget) {
      this.subjectTarget.value = body.subject_line;
      this.subjectTarget.dispatchEvent(new Event("input"));
    }
  }
}
