/* global document, window */

export default function useEventController(controller) {
  const controllerDisconnect = controller.disconnect.bind(controller);
  const abortEventController = new AbortController();
  const listeningOn = {};

  Object.assign(controller, {
    disconnect() {
      abortEventController.abort();
      controllerDisconnect();
    },

    listenOn(target, eventName, callback, options = {}) {
      this.trackEventListener(target, eventName, callback);
      target.addEventListener(eventName, callback.bind(this), { signal: abortEventController.signal, ...options });
    },

    listenOnDocument(eventName, callback, options = {}) {
      this.listenOn(document, eventName, callback, options);
    },

    listenOnWindow(eventName, callback, options = {}) {
      this.listenOn(window, eventName, callback, options);
    },

    listenOff(target, eventName = null) {
      const events = listeningOn[target] || [];
      listeningOn[target] = events.filter((targetEvent) => {
        if (targetEvent.eventName === eventName || eventName === null) {
          target.removeEventListener(targetEvent.eventName, targetEvent.callback);
          return false;
        }
        return true;
      });
    },

    trackEventListener(target, eventName, callback) {
      const boundCallback = callback.bind(this);
      const events = listeningOn[target] || [];
      events.push({ eventName, callback: boundCallback });
      listeningOn[target] = events;
    },
  });
}
