/* global document, window */

import { Turbo } from "@hotwired/turbo-rails";
import buffer from "buffer";

import "../controllers/index";

Turbo.session.drive = document.querySelector("body[data-driven-by-turbo]") !== null;

// Filestack uploading buffer
window.Buffer = window.Buffer || buffer.Buffer;
