/* eslint-env browser */
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["country", "state"];

  static values = {
    url: { type: String, default: "/customers/get_states_for_country" },
  };

  countryTargetConnected() {
    this.countryTarget.setAttribute("data-action", "change->country-state#updateStates");
  }

  async updateStates() {
    this.stateTarget.setAttribute("disabled", "");
    this.stateTarget.options.length = 0;

    const url = new URL(this.urlValue, document.baseURI);
    url.searchParams.set("country_name", this.countryTarget.value);
    const data = await fetch(url.toString()).then((response) => response.json());

    if (data.customers.length > 0) {
      data.customers.forEach((state) => this.stateTarget.appendChild(new Option(state.name, state.code)));
      this.stateTarget.removeAttribute("disabled");
    } else {
      this.stateTarget.appendChild(new Option());
    }
  }
}
