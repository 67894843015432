import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["inksoftOnboarding", "merchOnboarding"];

  showInksoftOnboardingStep(e) {
    this.showOnboardingStep(this.inksoftOnboardingTarget, e);
  }

  showMerchOnboardingStep(e) {
    this.showOnboardingStep(this.merchOnboardingTarget, e);
  }

  showOnboardingStep(onBoardingElement, e) {
    if (onBoardingElement) {
      onBoardingElement.classList.remove("display-none");
      e.target.classList.add("display-none");
    }
  }
}
