import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["plan", "warning", "prompt", "checkbox"];

  static values = {
    activeMerchSubscription: Boolean,
    currentAccountAllowsMerch: Boolean,
    planOptions: Array,
  };

  displayWarning(display) {
    this.warningTarget.classList.toggle("hidden", !display);
  }

  displayPrompt(display) {
    this.promptTarget.classList.toggle("hidden", !display);
  }

  hideAll() {
    this.displayWarning(false);
    this.displayPrompt(false);
  }

  check() {
    const selectedPlan = this.planOptionsValue.find((plan) => plan.id.toString() === this.planTarget.value);

    if (this.activeMerchSubscriptionValue) {
      if (!selectedPlan.merch && !selectedPlan.merch_addon) {
        // merch is not available on the selected plan
        this.displayPrompt(false);
        this.displayWarning(true);
      } else if (selectedPlan.merch) {
        // merch is included with selected plan
        this.hideAll();
      } else if (this.currentAccountAllowsMerchValue) {
        // account allows merch and plan allows merch addon
        this.displayPrompt(true);
        this.displayWarning(!this.checkboxTarget.checked);
      }
    }
  }

  toggled(event) {
    this.displayWarning(!event.target.checked);
  }
}
