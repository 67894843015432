/* eslint-env browser */

import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import { fetchStream } from "../helpers/fetch";

export default class extends Controller {
  static values = {
    url: String,
    method: {
      type: String,
      default: "GET",
    },
    body: {
      type: Object,
      default: {},
    },
  };

  render() {
    fetchStream(this.url, { method: this.methodValue, body: this.bodyValue })
      .then((response) => (response.ok ? response.text() : ""))
      .then((html) => Turbo.renderStreamMessage(html))
      .finally(() => this.dispatch("finished"));
  }

  get url() {
    return this.urlValue || this.element.href;
  }
}
