/* global document, Stripe */
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["addressForm", "error", "forward", "paymentForm", "spinner", "submitText"];

  static values = {
    generalError: String,
    stripeKey: String,
  };

  static outlets = ["stripe--billing-details"];

  static appearance = {
    variables: {
      colorText: "rgb(56, 70, 78)",
      fontFamily: "'Proxima Nova', 'Helvetica Neue', Arial",
    },
    rules: {
      ".Label": {
        fontSize: "14px",
        fontWeight: "700",
      },
      ".Input": {
        borderColor: "#e7eef2",
        borderWidth: "2px",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "18px",
        padding: "6px 12px",
      },
    },
  };

  async fetchSetupIntent() {
    const response = await fetch("/accounts/payment_methods/new");
    if (response.status === 200) {
      const responseBody = await response.json();
      return responseBody.setup_intent_secret;
    }
    return null;
  }

  connect() {
    this.stripejs = Stripe(this.stripeKeyValue);
  }

  setup() {
    this.clearError();

    this.fetchSetupIntent()
      .then((setupIntent) => {
        this.stripeElements = this.stripejs.elements({
          clientSecret: setupIntent,
          appearance: this.constructor.appearance,
        });

        this.paymentElement = this.stripeElements.create("payment", {
          layout: "tabs",
          fields: {
            billingDetails: {
              address: {
                postalCode: "never",
                country: "never",
              },
            },
          },
        });
        this.addressElement = this.stripeElements.create("address", { mode: "billing" });

        this.paymentElement.mount(this.paymentFormTarget);
        this.addressElement.mount(this.addressFormTarget);
      })
      .catch(() => this.displayError(this.generalErrorValue));
  }

  submit(event) {
    event.preventDefault();

    this.spinnerTarget.classList.remove("hidden");
    this.submitTextTarget.classList.add("hidden");
    this.clearError();

    // When a non-redirect based payment method is successfully confirmed, stripe.confirmSetup will resolve with a {setupIntent} object.
    this.stripejs
      .confirmSetup({
        elements: this.stripeElements,
        redirect: "if_required",
      })
      .then((response) => {
        this.spinnerTarget.classList.add("hidden");
        this.submitTextTarget.classList.remove("hidden");

        if (response.error) {
          this.displayError(response.error.message);
        } else {
          this.appendPaymentMethodToForm(response.setupIntent.payment_method);

          this.paymentElement.destroy();
          this.addressElement.destroy();

          this.stripeBillingDetailsOutlets.forEach((billingDetails) =>
            billingDetails.fetch(response.setupIntent.payment_method),
          );

          this.forwardTarget.click();
        }
      });
  }

  displayError(message) {
    this.errorTarget.textContent = message;
    this.errorTarget.classList.remove("hidden");
    this.errorTarget.scrollIntoView({ behavior: "smooth", block: "end" });
  }

  clearError() {
    this.errorTarget.classList.add("hidden");
    this.errorTarget.textContent = "";
  }

  appendPaymentMethodToForm(paymentMethodId) {
    const hiddenInput = Object.assign(document.createElement("input"), {
      type: "hidden",
      name: "stripe_payment_method_id",
      value: paymentMethodId,
    });
    hiddenInput.dataset["stripe-TaxCalculationTarget"] = "paymentMethod";

    this.element.appendChild(hiddenInput);
  }
}
