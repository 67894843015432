import { Application } from "stimulus";
import { Popover, Tabs, Toggle } from "tailwindcss-stimulus-components";
import { registerControllers } from "stimulus-vite-helpers";
import revealController from "stimulus-reveal-controller";

// Start the Stimulus application.
const application = Application.start();
application.register("popover", Popover);
application.register("tw-tabs", Tabs);
application.register("toggle", Toggle);
application.register("reveal", revealController);
application.debug = process.env.NODE_ENV === "development";

// Controller files must be named *_controller.js.
const controllers = import.meta.glob("./**/*_controller.js", { eager: true });

// View component stimulus controllers
const componentControllers = import.meta.glob("./../../components/**/*_controller.js", { eager: true });
Object.keys(componentControllers).forEach((path) => {
  const module = componentControllers[path];
  const name = path
    .match(/components\/(.+\/.+)_controller\.js$/)[1]
    .replaceAll("_", "-")
    .replaceAll("/", "--");

  // Lower casing to avoid value change callback issues with capitalized folder structures
  application.register(name.toLowerCase(), module.default);
});

registerControllers(application, controllers);
