/* global document, CustomEvent */
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "toggle"];

  static values = {
    checked: Boolean,
  };

  checkedValueChanged(newValue) {
    this.element.setAttribute("aria-checked", newValue);
    this.inputTarget.checked = newValue;
  }

  toggle() {
    this.checkedValue = !this.checkedValue;

    const event = new CustomEvent("toggle:click", {
      detail: {
        element: this.element,
        target: this.inputTarget,
        checked: this.checkedValue,
      },
    });

    document.dispatchEvent(event);
  }
}
