import { Controller } from "stimulus";
import AutoNumeric from "autonumeric";

export default class extends Controller {
  static targets = [
    "refundButton",
    "cancelButton",
    "allOrdersCheckbox",
    "orderCheckbox",
    "orderAmount",
    "totalOrders",
    "totalRefundAmount",
  ];

  static values = {
    bookId: String,
    currencySymbol: String,
    currencyExponent: Number,
  };

  refundState = {};

  totalRefundAmountTargetConnected(target) {
    target.autonumeric = new AutoNumeric(target, this.autonumericDefaultOptions());
    this.updateTotals();
  }

  orderAmountTargetConnected(target) {
    const options = {
      ...this.autonumericDefaultOptions(),
      minimumValue: 0,
      maximumValue: target.getAttribute("max"),
    };

    const initialValue = this.refundState[target.dataset.orderId]?.amount || 0;
    target.autonumeric = new AutoNumeric(target, options);
    target.autonumeric.set(initialValue);
  }

  orderToggled(event) {
    const { target } = event;
    const input = this.orderInput(target.dataset.orderId);
    if (target.checked) {
      input.autonumeric.set(target.dataset.amountRemaining);
    } else {
      input.autonumeric.set(0);
    }
  }

  toggleAllOrders(event) {
    const { target } = event;
    this.orderCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = target.checked;
      this.orderToggled({ target: checkbox });
    });
  }

  orderInput(orderId) {
    return this.orderAmountTargets.find((target) => target.dataset.orderId === orderId);
  }

  orderCheckbox(orderId) {
    return this.orderCheckboxTargets.find((target) => target.dataset.orderId === orderId);
  }

  orderAmountUpdated(event) {
    const { target } = event;

    if (event.target.autonumeric) {
      const checkbox = this.orderCheckbox(target.dataset.orderId);
      checkbox.checked = !!event.target.autonumeric.getNumber() || false;
      this.updateState(checkbox, target);
    }
  }

  updateState(orderCheckbox, orderInput) {
    if (orderCheckbox.checked) {
      this.refundState[orderInput.dataset.orderId] = {
        amount: orderInput.autonumeric.getNumber(),
      };
    } else {
      delete this.refundState[orderInput.dataset.orderId];
    }

    setTimeout(() => {
      this.allOrdersCheckboxTarget.checked = !this.orderCheckboxTargets.find((checkbox) => !checkbox.checked);
    });

    this.updateTotals();
  }

  updateTotals() {
    if (this.hasTotalOrdersTarget) {
      this.totalOrdersTarget.textContent = Object.keys(this.refundState).length;
    }

    if (this.hasTotalRefundAmountTarget) {
      const totalRefundAmount = Object.values(this.refundState).reduce((total, order) => total + order.amount, 0);
      this.totalRefundAmountTarget.autonumeric?.set(totalRefundAmount);
      this.refundButtonTarget.disabled = !totalRefundAmount;
    }
  }

  autonumericDefaultOptions() {
    return {
      currencySymbol: this.currencySymbolValue,
      decimalPlaces: this.currencyExponentValue,
    };
  }
}
