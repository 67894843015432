/* eslint-env browser */

import { Controller } from "stimulus";

export default class extends Controller {
  static outlets = ["statements--refund-modal"];

  static targets = ["void"];

  static values = { refundUrl: String };

  voidTargetConnected() {
    this.statementsRefundModalOutlet.refundButtonTarget.disabled = false;
  }

  submitRefund() {
    this.submit();
  }

  async submit() {
    const body = this.hasVoidTarget ? { void: true } : { orders: this.statementsRefundModalOutlet.refundState };

    this.statementsRefundModalOutlet.refundButtonTarget.disabled = true;
    const response = await fetch(this.refundUrlValue, {
      body: JSON.stringify({ id: this.statementsRefundModalOutlet.bookIdValue, ...body }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")?.content,
      },
    });

    if (!response.ok) {
      const flash = Object.entries(await response.json())[0];
      this.statementsRefundModalOutlet.refundButtonTarget.disabled = false;
      window.displayFlash(flash[0], flash[1], this.statementsRefundModalOutlet.element);
    } else {
      window.location.reload();
    }
  }
}
