/* global $ */

import { Controller } from "stimulus";

export default class extends Controller {
  static values = { optionTypeNames: String };

  connect() {
    try {
      const optionTypeNames = JSON.parse(this.optionTypeNamesValue);
      if (optionTypeNames) {
        this.configureDragAndDrop(optionTypeNames);
      }
    } catch {
      // eslint-disable-next-line no-console
      console.error(
        "Error parsing option type names or configuring Sortable, optionTypeNamesValue value was:",
        this.optionTypeNamesValue,
      );
    }
  }

  // Configures drag and drop spots on the page, this relies on jQuery Ui Sortable
  configureDragAndDrop(optionTypeNames) {
    optionTypeNames.forEach((optionTypeName) => {
      $(`#option-type-${optionTypeName}-draggable-area`).sortable({
        items: ".fields",
        stop(event) {
          const inputFields = event.target.querySelectorAll("input.option-value-position");
          let startingPosition = 1;
          inputFields.forEach((inputField) => {
            inputField.value = startingPosition;
            startingPosition += 1;
          });
        },
      });
    });
  }
}
