/* eslint-env browser */
import { Controller } from "stimulus";
import AutoNumeric from "autonumeric";

export default class extends Controller {
  static outlets = ["statements--editor"];

  static targets = ["order", "requestedAmount"];

  static classes = ["selected"];

  requestedAmountTargetConnected(element) {
    const selectedOrder = this.statementsEditorOutlet.selectedOrdersValue.find(
      (order) => order.order_id === element.dataset.order,
    );

    new AutoNumeric(element).set(selectedOrder?.amount || "0.00");
  }

  orderTargetConnected(element) {
    const checkbox = element.querySelector("input[type='checkbox']");

    const selectedOrder = this.statementsEditorOutlet.selectedOrdersValue.find(
      (order) => order.order_id === checkbox.dataset.orderId,
    );

    // eslint-disable-next-line no-underscore-dangle
    if (!selectedOrder || selectedOrder._destroy === "1") {
      checkbox.checked = false;
      return;
    }

    checkbox.checked = true;
  }

  allOrdersToggled(event) {
    this.orderTargets.forEach((target) => {
      const checkbox = target.querySelector("input[type='checkbox']");

      if (checkbox.checked === event.target.checked) return;

      checkbox.checked = event.target.checked;

      this.orderToggled({ target: checkbox });
    });
  }

  orderToggled(event) {
    const target = event.target.closest("tr");
    target.classList.toggle(this.selectedClass, event.target.checked);

    this.dispatchOrderUpdate(event.target, event.target.dataset, event.target.checked);
  }

  orderAmountUpdated(event) {
    const target = event.target.closest("tr");
    const checkbox = target.querySelector("[type='checkbox']");
    const amount = AutoNumeric.getNumber(event.target) || 0;

    checkbox.dataset.amount = amount;

    if (Number(amount)) {
      checkbox.checked = true;

      const error = target.querySelector("[data-component='statement_order_amount_error']");
      error.classList.toggle("hidden", amount >= 2);
      event.target.setCustomValidity(amount >= 2 ? "" : error.textContent);
    }

    this.dispatchOrderUpdate(event.target, checkbox.dataset, checkbox.checked);
  }

  dispatchOrderUpdate(target, order, checked) {
    this.dispatch("update-order", { target, detail: { order, checked } });
  }
}
