/* global $ window */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cacheVersion"];

  static values = { pollUrl: String, pollCacheKey: String, reloadUrl: String };

  connect() {
    this.merch_polling = setInterval(() => this.pollPendingTable(), 2000);
  }

  disconnect() {
    clearInterval(this.merch_polling);
  }

  deleteStore(event) {
    $.ajax({
      url: event.params.url,
      dataType: "json",
      type: "DELETE",
      success: () => this.reloadStores(),
    });
  }

  pollPendingTable() {
    $.ajax({
      url: this.pollUrlValue,
      dataType: "json",
      success: (data) => {
        if (data.key !== this.pollCacheKeyValue) {
          this.reloadStores();
        }
      },
    });
  }

  reloadStores() {
    if (this.element.dataset.turbo === "true") {
      this.element.src = this.reloadUrlValue;
    } else {
      window.location.reload();
    }
  }
}
