import { Controller } from "stimulus";

export default class extends Controller {
  FORM_ACTION_START = "turbo-form-loading#start";

  FORM_ACTION_FINISH = "turbo:submit-end->turbo-form-loading#finish";

  static targets = ["visible", "hidden", "form"];

  formTargetConnected(form) {
    const action = form.dataset.action || "";
    form.dataset.action = `${action} ${this.FORM_ACTION_START} ${this.FORM_ACTION_FINISH}`.trim();
  }

  formTargetDisconnected(form) {
    const action = form.dataset.action || "";
    form.dataset.action = action.replaceAll(this.FORM_ACTION_START, "").replaceAll(this.FORM_ACTION_FINISH, "").trim();
  }

  start() {
    this.visibleTargets.forEach((e) => e.classList.remove("hidden"));
    this.hiddenTargets.forEach((e) => e.classList.add("hidden"));
  }

  finish() {
    // setTimeout: Give the browser a chance to render the change before we show it
    setTimeout(() => {
      this.visibleTargets.forEach((e) => e.classList.add("hidden"));
      this.hiddenTargets.forEach((e) => e.classList.remove("hidden"));
    });
  }
}
