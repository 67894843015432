/* global $, FormData, Turbo, window */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "rangeDatePicker", "paid", "paidButton", "unpaidButton"];

  static classes = ["activeButton"];

  connect() {
    $(this.rangeDatePickerTarget).on("hide.daterangepicker", () => this.submitForm());
    $(this.formTarget).on("hidden.bs.dropdown", () => this.submitForm());
    if (this.hasPaidTarget) {
      this.renderPaid();
    }
  }

  disconnect() {
    $(this.rangeDatePickerTarget).off("hide.daterangepicker");
    $(this.formTarget).off("hidden.bs.dropdown");
  }

  togglePaid(e) {
    if (this.paidTarget.value === "true") {
      this.paidTarget.value = "";
    } else {
      this.paidTarget.value = "true";
    }
    e.preventDefault();
    this.renderPaid();
    this.submitForm();
  }

  toggleUnpaid(e) {
    if (this.paidTarget.value === "false") {
      this.paidTarget.value = "";
    } else {
      this.paidTarget.value = "false";
    }
    e.preventDefault();
    this.renderPaid();
    this.submitForm();
  }

  renderPaid() {
    this.paidButtonTarget.classList.remove(this.activeButtonClass);
    this.unpaidButtonTarget.classList.remove(this.activeButtonClass);

    if (this.paidTarget.value === "true") {
      this.paidButtonTarget.classList.add(this.activeButtonClass);
    } else if (this.paidTarget.value === "false") {
      this.unpaidButtonTarget.classList.add(this.activeButtonClass);
    }
  }

  get baseUrl() {
    return `${window.location.href.replace(window.location.search, "")}`;
  }

  submitForm() {
    $("#no-results").remove();

    if ($("#analytics-loading").length === 0) {
      $("#analytics-title").append(
        "<span id='analytics-loading' class='text-center text-muted'>Crunching data...</span>",
      );
    }

    const url = `${this.baseUrl}?${new URLSearchParams(new FormData(this.formTarget)).toString()}`;

    window.history.pushState({}, "", url);
    Turbo.navigator.submitForm(this.formTarget);
  }

  export(e) {
    const url = `${this.baseUrl}.csv?${new URLSearchParams(new FormData(this.formTarget)).toString()}`;

    fetch(url)
      .then(
        (response) =>
          new Promise((resolve) => {
            response.text().then((text) =>
              resolve({
                ok: response.ok,
                text,
              }),
            );
          }),
      )
      .then(({ ok, text }) => {
        if (ok) {
          window.displayFlash("success", text);
        } else {
          window.displayFlash("notice", text || "Something went wrong");
        }
      });

    e.preventDefault();
  }
}
