import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["radioButton"];

  connect() {
    this.checkDefault();
  }

  checkDefault() {
    const select = this.radioButtonTargets.find((radioButton) => radioButton.checked) || this.radioButtonTargets[0];
    if (select) select.checked = true;

    this.uncheckOthers(select);
  }

  uncheckOthers(target) {
    this.radioButtonTargets.forEach((radioButton) => {
      if (radioButton !== target) {
        radioButton.checked = false;
      }
    });
  }

  selected(event) {
    this.uncheckOthers(event.target);
  }

  removed(event) {
    const fields = event.target.closest(".fields");
    const radioButton = fields.querySelector("[data-customers--billing-contact-select-target='radioButton']");

    if (radioButton?.checked) {
      radioButton.checked = false;
      this.checkDefault();
    }
  }
}
