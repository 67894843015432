/* global c3, d3 */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["chart"];

  static values = { type: String, data: Object, yFormat: String };

  connect() {
    if (this.typeValue === "timeseries") {
      this.connectTimeSeries();
    } else if (this.typeValue === "pie") {
      this.connectPieChart();
    }
  }

  connectTimeSeries() {
    const chartConfig = {
      bindto: `#${this.chartTarget.id}`,
      data: {
        x: "x",
        columns: this.dataValue.columns,
      },
      color: {
        pattern: this.dataValue.colors,
      },
      axis: {
        x: {
          type: this.typeValue,
          tick: {
            format: "%m/%d/%Y",
          },
        },
      },
    };

    if (this.hasYFormatValue) {
      chartConfig.axis.y = {
        tick: {
          format: d3.format(this.yFormatValue),
        },
      };
    }

    this.chart = c3.generate(chartConfig);
  }

  connectPieChart() {
    this.chart = c3.generate({
      bindto: `#${this.chartTarget.id}`,
      data: {
        columns: this.dataValue.columns,
        type: "pie",
      },
      pie: {
        label: {
          format: (value) => d3.format("$,.2f")(value),
        },
      },
    });
  }

  disconnect() {
    this.chart.destroy();
  }
}
