/* eslint-env browser */
/* global $ */

import { Turbo } from "@hotwired/turbo-rails";
import { Controller } from "stimulus";
import useFocusTrap from "../../../../composables/use_focus_trap";
import useEventController from "../../../../composables/use_event_controller";

const placeholder = { id: "", text: "New Invoice", defaultSelected: true };

export default class extends Controller {
  static targets = ["select", "storeId", "form", "submitButton", "customerSelectForm"];

  static values = {
    selectCustomerDisabledText: String,
    selectCustomerText: String,
  };

  connect() {
    useEventController(this);
    $(this.selectTarget)
      .select2({
        ajax: {
          url: "/invoices.json",
          delay: 250,
          data: (params) => this.requestData(params),
          processResults: (data) => this.processResults(data),
        },
        data: [placeholder],
        cache: true,
        allowClear: false,
        width: "100%",
        dropdownParent: $("#invoice-select-modal"),
        class: "edit-invoice__customer-select2",
        required: false,
      })
      .on("select2:opening", () => {
        $(this.selectTarget)
          .data("select2")
          .$dropdown.find("input.select2-search__field")
          .attr("placeholder", "Search");
      });

    useFocusTrap(this);
    this.selectTarget.focus();

    this.boundCustomerModalListener = this.customerSelectModalOpened.bind(this);
    document.addEventListener("select-customer-modal-opened", this.boundCustomerModalListener);
  }

  formTargetConnected() {
    this.buttonClickListener = this.submit.bind(this);
    this.submitButtonTarget.addEventListener("click", this.buttonClickListener);
  }

  formTargetDisconnected() {
    this.submitButtonTarget.removeEventListener("submit", this.buttonClickListener);
  }

  customerSelectFormTargetConnected() {
    this.buttonClickListener = this.customerSelectFormSubmit.bind(this);
    this.submitButtonTarget.addEventListener("click", this.buttonClickListener);
  }

  customerSelectFormTargetDisconnected() {
    this.submitButtonTarget.removeEventListener("submit", this.buttonClickListener);
  }

  selectTargetDisconnected(select) {
    $(select).select2("destroy");
  }

  disconnect() {
    if (this.boundCustomerModalListener) {
      document.removeEventListener("select-customer-modal-opened", this.boundCustomerModalListener);
    }
  }

  async submit() {
    const response = await fetch(this.formTarget.action, {
      body: new FormData(this.formTarget),
      method: (this.formTarget.querySelector("[name='_method']")?.value ?? this.formTarget.method).toUpperCase(),
      headers: {
        "X-CSRF-Token": document.head.querySelector("meta[name='csrf-token']")?.content,
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (response.redirected) {
      Turbo.visit(response.url);
    } else if (response.ok) {
      Turbo.renderStreamMessage(await response.text());
    } else {
      try {
        const error = await response.json();
        window.displayFlash("notice", error.message);
      } catch {
        window.displayFlash("notice", "An error occurred. Please try again.");
      }
    }
  }

  async customerSelectFormSubmit() {
    this.customerSelectFormTarget.requestSubmit();
  }

  requestData(params) {
    return {
      search: {
        status: "invoice",
        query: params.term ?? "",
        source: "inksoft",
        store_id: this.storeIdTarget.value,
      },
      per_page: 10,
    };
  }

  processResults(data) {
    const invoices = data.results ?? [];

    const results = invoices.map((invoice) => ({
      id: invoice.id,
      text: invoice.visual_id + (invoice.order_nickname ? ` - ${invoice.order_nickname}` : ""),
    }));

    if (results.length === 10) {
      results.push({
        text: "To view other orders, use the search tool to pull from the full order list.",
      });
    }

    return {
      results: [{ ...placeholder, selected: true }, ...results],
    };
  }

  customerSelectModalOpened() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.textContent = this.selectCustomerTextValue;
      this.submitButtonTarget.dataset.disableWith = this.selectCustomerDisabledTextValue;
    }
  }
}
