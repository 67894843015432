/* global $, document */

import { Controller } from "stimulus";
import useFocusTrap from "../../composables/use_focus_trap";

export default class extends Controller {
  static targets = ["discardButton", "saveButton"];

  static values = {
    form: String,
    redirect: String,
  };

  connect() {
    useFocusTrap(this);

    this.element.focus();
  }

  cancelFocusTrap() {
    this.modalElement.modal("hide");
  }

  cancel() {
    this.redirectElement.value = "";
  }

  show(event) {
    const { href } = event.target;

    this.redirectElement.value = href;
    this.discardButtonTarget.href = href;

    this.modalElement.modal("show");
  }

  submit() {
    this.formElement.submit();
  }

  get formElement() {
    return document.querySelector(this.formValue);
  }

  get modalElement() {
    return $(this.element);
  }

  get redirectElement() {
    return this.formElement.querySelector(this.redirectValue);
  }
}
