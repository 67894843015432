/* global Dinero */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "error",
    "plan",
    "paymentMethod",
    "keepMerch",
    "implementationAndTraining",
    "planSubtotal",
    "planTax",
    "planTotal",
    "merchSubtotal",
    "merchTax",
    "merchTotal",
    "merchChargeBreakdown",
    "merchAddon",
  ];

  static values = {
    merchablePlans: Array,
  };

  calculate(response) {
    const { amountTotal, currency, taxAmountExclusive } = response;

    const subtotal = response.lineItems.data
      .reduce(
        (sum, lineItem) => sum.add(Dinero({ amount: Number(lineItem.amount), currency })),
        Dinero({ amount: 0, currency }),
      )
      .toFormat();

    return {
      subtotal,
      tax: Dinero({ amount: taxAmountExclusive, currency }).toFormat(),
      total: Dinero({ amount: amountTotal, currency }).toFormat(),
    };
  }

  clear() {
    this.errorTarget.classList.add("hidden");
    this.merchChargeBreakdownTarget.classList.add("hidden");
    this.merchAddonTarget.classList.add("hidden");

    this.planSubtotalTarget.innerText = "";
    this.planTaxTarget.innerText = "";
    this.planTotalTarget.innerText = "";
  }

  async fetch() {
    this.clear();

    const planId = this.planTarget.selectedOptions[0].value;
    const paymentMethodId = this.hasPaymentMethodTarget ? this.paymentMethodTarget.value : "";
    const trainingKey = this.hasImplementationAndTrainingTarget
      ? this.implementationAndTrainingTarget.selectedOptions[0].value
      : "";

    let keepMerch;
    if (this.hasKeepMerchTarget) {
      keepMerch = this.keepMerchTarget.checked && this.merchablePlansValue.includes(Number(planId));
    } else {
      keepMerch = false;
    }
    this.merchAddonTarget.classList.toggle("hidden", !keepMerch);

    const response = await fetch(
      `/accounts/subscriptions/tax?account_plan_id=${planId}&payment_method_id=${paymentMethodId}&merch_addon=${keepMerch}&training_key=${trainingKey}`,
    );
    if (response.status === 200) {
      const responseBody = await response.json();

      {
        const { subtotal, tax, total } = this.calculate(responseBody.planTaxCalculation);

        this.planSubtotalTarget.innerText = subtotal;
        this.planTaxTarget.innerText = tax;
        this.planTotalTarget.innerText = total;
      }

      if (responseBody.merchTaxCalculation) {
        const { subtotal, tax, total } = this.calculate(responseBody.merchTaxCalculation);

        this.merchSubtotalTarget.innerText = subtotal;
        this.merchTaxTarget.innerText = tax;
        this.merchTotalTarget.innerText = total;
        this.merchChargeBreakdownTarget.classList.remove("hidden");
      }
    } else {
      this.errorTarget.innerText = "Error retrieving tax information.";
      this.errorTarget.classList.remove("hidden");
    }
  }
}
