/* global document */

export default function useFocusTrap(controller, options = {}) {
  const backwardKeys = ["ArrowLeft", "ArrowUp"];
  const forwardKeys = ["ArrowRight", "ArrowDown"];

  const selectors = ["a", "button", "input", "select", "textarea", "[tabindex]:not([tabindex='-1'])"].map(
    (selector) => `${selector}:not([disabled])`,
  );

  const onEvent = (event) => {
    const targetElement = options?.element || controller.element;
    const { display, visibility } = targetElement.style;

    if (![...forwardKeys, ...backwardKeys, "Escape", "Tab"].includes(event.key)) {
      return;
    }

    if (display === "none" || visibility === "hidden" || targetElement.dataset["aria-hidden"] === "true") {
      return;
    }

    if (!targetElement.contains(document.activeElement)) {
      return;
    }

    event.preventDefault();

    const elements = Array.from(targetElement.querySelectorAll(selectors.join(", ")));

    if (event.key === "Escape" && controller.cancelFocusTrap) {
      controller.cancelFocusTrap(event);
      return;
    }

    let index = elements.findIndex((element) => element === document.activeElement);

    if (backwardKeys.includes(event.key) || (event.shiftKey && event.key === "Tab")) {
      index = index === 0 ? elements.length - 1 : index - 1;
    }

    if (forwardKeys.includes(event.key) || (!event.shiftKey && event.key === "Tab")) {
      index = index === elements.length - 1 ? 0 : index + 1;
    }

    elements[index].focus();
  };

  const observe = () => document.addEventListener("keydown", onEvent, true);
  const unobserve = () => document.removeEventListener("keydown", onEvent, true);

  const controllerDisconnect = controller.disconnect.bind(controller);

  Object.assign(controller, {
    disconnect() {
      unobserve();
      controllerDisconnect();
    },
  });

  observe();

  return [observe, unobserve];
}
