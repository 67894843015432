/* global window, CustomEvent */
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["pane"];

  connect() {
    this.last = [this.paneTargets[0]];
  }

  forward(event) {
    const current = event.target.closest("[data-wizard-form-target=pane]");
    current.classList.add("hidden");
    this.last.push(current);

    this.paneTargets.find((target) => target.role === event.params.forward)?.classList?.remove("hidden");
    const externalEvent = new CustomEvent(`wizard-form-${event.params.forward}`, { bubbles: true });
    window.dispatchEvent(externalEvent);
  }

  back(event) {
    event.target.closest("[data-wizard-form-target=pane]")?.classList?.add("hidden");
    const lastPane = this.last.pop();
    lastPane.classList.remove("hidden");
    const externalEvent = new CustomEvent(`wizard-form-${lastPane.role}`, { bubbles: true });
    window.dispatchEvent(externalEvent);
  }
}
