import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    undoString: String,
    markReadyString: String,
    addToInvoiceString: String,
  };

  update(selectedFilter) {
    this.selectedFilter = selectedFilter;
    switch (selectedFilter) {
      case "ready_to_fulfill":
        this.element.textContent = this.undoStringValue;
        break;
      case "in_production":
        this.element.textContent = this.markReadyStringValue;
        break;
      default:
        this.element.textContent = this.addToInvoiceStringValue;
        break;
    }
  }

  performAction() {
    if (this.selectedFilter === "ready_to_fulfill" || this.selectedFilter === "in_production") {
      this.dispatch("update-fulfillment-status", { prefix: false });
    } else {
      this.dispatch("open-add-to-invoice-modal", { prefix: false });
    }
  }
}
