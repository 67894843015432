/* eslint-env browser */
import { Controller } from "stimulus";
import { easepick, RangePlugin } from "@easepick/bundle";

export default class extends Controller {
  static targets = ["calendar", "endDate", "endOfDay", "startDate", "startOfDay"];

  connect() {
    // eslint-disable-next-line
    this.datepicker = new easepick.create({
      css: [
        "https://cdn.jsdelivr.net/npm/@easepick/core@1.2.1/dist/index.css",
        "https://cdn.jsdelivr.net/npm/@easepick/range-plugin@1.2.1/dist/index.css",
      ],
      element: this.calendarTarget,
      calendars: 2,
      firstDay: 0,
      grid: 2,
      inline: true,
      plugins: [RangePlugin],
      setup: this.configureDatepickerEvents.bind(this),
      RangePlugin: { tooltip: true },
    });
  }

  configureDatepickerEvents(datepicker) {
    datepicker.on("select", (event) => {
      const { start, end } = event.detail;

      if (start === null || end === null) {
        return;
      }

      this.startDateTarget.value = start.format("YYYY-MM-DD");
      this.startOfDayTarget.value = window.moment(start).startOf("day").toISOString(false);

      this.endDateTarget.value = end.format("YYYY-MM-DD");
      this.endOfDayTarget.value = window.moment(end).endOf("day").toISOString(false);

      this.dispatchUpdateEvent();
    });
  }

  dateInputChanged(event) {
    if (event.target.value.startsWith("0")) {
      return;
    }

    if (this.startDateTarget.value !== "" && this.endDateTarget.value !== "") {
      this.datepicker.setDateRange(this.startDateTarget.value, this.endDateTarget.value);

      this.startOfDayTarget.value = window.moment(this.startDateTarget.value).startOf("day").toISOString(false);
      this.endOfDayTarget.value = window.moment(this.endDateTarget.value).endOf("day").toISOString(false);

      this.dispatchUpdateEvent();
    }

    this.datepicker.gotoDate(this.startDateTarget.value || this.endDateTarget.value);
  }

  dispatchUpdateEvent() {
    const startDate = window.moment(this.startDateTarget.value).toDate();
    const endDate = window.moment(this.endDateTarget.value).toDate();

    window.dispatchEvent(new CustomEvent("datepicker:update", {
      target: this.element,
      detail: {
        label: `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`,
      },
    }));

    if (this.element.dataset.submitOnSelect === "true" && this.startDateTarget.form) {
      this.startDateTarget.form.requestSubmit();
    }
  }

  reset() {
    this.startDateTarget.value = "";
    this.endDateTarget.value = "";
    this.startOfDayTarget.value = "";
    this.endOfDayTarget.value = "";

    if (this.element.dataset.submitOnSelect === "true" && this.startDateTarget.form) {
      this.startDateTarget.form.requestSubmit();
    }
  }
}
