/* global $ */

import { Controller } from "stimulus";
import useFocusTrap from "../../composables/use_focus_trap";
import useEventController from "../../composables/use_event_controller";

export default class extends Controller {
  static targets = ["select"];

  static values = {
    selectCustomerDisabledText: String,
    selectCustomerText: String,
  };

  connect() {
    useEventController(this);

    this.dispatch("select-customer-modal-opened", { prefix: false });

    this.disableButton(true);

    $(this.selectTarget)
      .select2({
        ajax: {
          url: "/customers/search",
          delay: 250,
          data: (params) => this.requestData(params),
          processResults: (data) => this.processResults(data),
        },
        cache: true,
        width: "100%",
        placeholder: "Select a Customer",
        dropdownParent: $("#invoice-select-modal"),
        class: "edit-invoice__customer-select2",
        required: true,
      })
      .on("change", () => {
        this.disableButton(false);
      });

    useFocusTrap(this);
    this.selectTarget.focus();
  }

  disconnect() {
    $(this.selectTarget).select2("destroy");
  }

  requestData(params) {
    return {
      search: {
        query: params.term ?? "",
      },
    };
  }

  processResults(data) {
    const customers = data.search ?? [];

    customers.forEach((customer) => {
      customer.text = customer.full_name_and_company;
    });

    return { results: customers };
  }

  disableButton(disable) {
    $("#add-to-invoice-button").prop("disabled", disable);
  }
}
