import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menuItem", "input", "search"];

  static values = {
    maxSearchResults: Number,
  };

  connect() {
    if (this.maxSearchResultsValue) {
      const [header, ...rest] = this.menuItemTargets;
      header.style.display = "none";

      rest.entries().forEach((i, item) => {
        item.style.display = i < this.maxSearchResultsValue ? "block" : "none";
      });
    }
  }

  filterItems() {
    const searchTerm = this.searchTarget.value.toLowerCase();

    let count = 0;
    this.menuItemTargets.slice(1).forEach((item) => {
      if (item.innerText.toLowerCase().includes(searchTerm) && count < this.maxSearchResultsValue) {
        item.style.display = "block";
        count += 1;
      } else {
        item.style.display = "none";
      }
    });
  }

  resetFilter() {
    this.menuItemTargets[0].click();

    this.searchTarget.value = "";
    this.filterItems();
  }

  selectItem(event) {
    const { value } = event.target.dataset;

    if (this.hasInputTarget) {
      this.inputTarget.value = value;
    }

    const firstSelected = this.menuItemTargets[0].dataset.value === value;

    this.dispatch("selectedItem", {
      prefix: "menu",
      detail: { event, value, selectionChanged: !firstSelected },
    });

    if (event.target.form) {
      event.preventDefault();
      event.target.form.requestSubmit();
    }
  }
}
