/* global Fancybox */

import { Controller } from "stimulus";
import useEventController from "../composables/use_event_controller";

export default class extends Controller {
  connect() {
    useEventController(this);

    this.element
      .querySelectorAll("[data-fancybox]")
      .forEach((element) => this.listenOn(element, "click", Fancybox.fromEvent));
  }
}
