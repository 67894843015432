/* global document */

import { Controller } from "stimulus";
import useEventController from "../composables/use_event_controller";

export default class extends Controller {
  static targets = ["container", "search", "clearButton"];

  connect() {
    useEventController(this);

    this.listenOn(this.searchTarget, "blur", this.closeUnfocusedSearch.bind(this));
    this.listenOn(this.clearButtonTarget, "blur", this.closeUnfocusedSearch.bind(this));
  }

  openSearch() {
    this.containerTarget.classList.remove("!hidden");
    this.searchTarget.focus();
  }

  closeUnfocusedSearch() {
    setTimeout(() => {
      if (this.containerTarget.contains(document.activeElement)) {
        return;
      }

      this.containerTarget.classList.add("!hidden");
    }, 70);
  }
}
