import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["required", "submit"];

  submitTargetConnected(element) {
    element.disabled = true;
  }

  update() {
    const someUnchecked = this.requiredTargets.some((field) => field.checked === false);
    this.submitTargets.forEach((target) => {
      target.disabled = someUnchecked;
    });
  }
}
