import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["plan", "errors", "continue"];

  async check() {
    this.errorsTarget.innerHTML = "";

    const response = await fetch(`/accounts/subscriptions/downgrade_messages?plan_id=${this.planTarget.value}`);

    if (response.status === 200) {
      const body = await response.text();
      this.errorsTarget.innerHTML = body;
      this.continueTarget.disabled = true;
    } else {
      this.continueTarget.disabled = false;
    }
  }
}
