/* eslint-env browser */

import { Controller } from "stimulus";
import "@hotwired/turbo-rails";

export default class extends Controller {
  connect() {
    this.frame = this.element.closest("turbo-frame");
    this.reloadBind = this.reload.bind(this);
    document.addEventListener("fulfillment-status-updated", this.reloadBind);
  }

  disconnect() {
    document.removeEventListener("fulfillment-status-updated", this.reloadBind);
  }

  reload() {
    this.frame.reload();
  }
}
