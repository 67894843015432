/* global document */

import { Controller } from "stimulus";
import { useDebounce } from "stimulus-use";

const MINIMUM_QUERY_LENGTH = 3;

export default class extends Controller {
  static debounces = ["search"];

  static targets = ["form", "search", "results"];

  static values = {
    endpoint: String,
  };

  connect() {
    useDebounce(this, { wait: 300 });
  }

  clearSearch() {
    if (this.searchTarget.value === "") {
      document.activeElement.blur();
    } else {
      this.searchTarget.focus();
      this.searchTarget.value = "";
    }

    this.dispatch("close");
  }

  focused() {
    if (this.searchTarget.value.length < MINIMUM_QUERY_LENGTH) {
      return;
    }

    this.dispatch("open");
  }

  search() {
    const query = this.searchTarget.value;

    if (query.length < MINIMUM_QUERY_LENGTH) {
      this.dispatch("close");

      return;
    }

    this.dispatch("open");

    fetch(this.endpointValue, {
      method: "POST",
      body: JSON.stringify({ query }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")?.content,
      },
    }).then((response) =>
      response.text().then((html) => {
        this.resultsTarget.innerHTML = html;
      }),
    );
  }

  submit() {
    this.formTarget.submit();
  }
}
