import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["accountPlan", "implementationAndTraining", "accountIcon"];

  static values = {
    accountPlan: Number,
    accountPlanOptions: Array,
    currentAccountPlan: Number,
    implementationAndTraining: { type: String, default: "waived" },
    implementationAndTrainingOptions: { type: Array, default: [] },
  };

  accountPlanValueChanged(value) {
    const currentPlan =
      this.accountPlanOptionsValue.find((option) => option.id === this.currentAccountPlanValue) ||
      this.accountPlanOptionsValue[0];

    const selectedPlan =
      this.accountPlanOptionsValue.find((option) => option.id === value) || this.accountPlanOptionsValue[0];

    this.accountPlanTarget.textContent = selectedPlan.name.replaceAll(" - ", " · ");

    if (!this.hasAccountIconTarget) {
      return;
    }

    Array.from(this.accountIconTarget.children).forEach((child) => child.classList.add("hidden"));

    if (selectedPlan.tier !== currentPlan.tier) {
      this.accountIconTarget
        .querySelector("[data-name=upgrade]")
        .classList.toggle("hidden", selectedPlan.tier < currentPlan.tier);

      this.accountIconTarget
        .querySelector("[data-name=downgrade]")
        .classList.toggle("hidden", selectedPlan.tier > currentPlan.tier);
    }
  }

  implementationAndTrainingValueChanged() {
    if (!this.hasImplementationAndTrainingTarget) {
      return;
    }

    const selected = this.implementationAndTrainingOptionsValue.find(
      (option) => option.key === this.implementationAndTrainingValue,
    );

    this.implementationAndTrainingTarget.textContent = selected.name.replaceAll(" - ", " · ");
  }

  update(event) {
    this.accountPlanValue = Number(event.target.value);
  }

  updateImplementationAndTraining(event) {
    this.implementationAndTrainingValue = event.target.value;
  }
}
