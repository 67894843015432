/* global Turbo window */

import { Controller } from "stimulus";
import useEventController from "../composables/use_event_controller";

export default class extends Controller {
  static values = {
    navigateUrl: String,
  };

  connect() {
    useEventController(this);

    this.listenOn(this.element, "click", this.navigate.bind(this));
  }

  navigate(event) {
    event.preventDefault();
    const href = this.navigateUrlValue || this.element.getAttribute("href");
    if (this.element.dataset.turbo) {
      Turbo.visit(href, { frame: this.element.dataset.turboFrame });
    } else {
      window.location.href = href;
    }
  }
}
