/* eslint-env browser */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["statusFilter", "undoInput", "submitButton"];

  static outlets = ["inksoft--store--action-button"];

  static values = {
    markReadyUrl: String,
  };

  connect() {
    this.inputs = [];
    this.statusFilterTargets.forEach((target) => {
      this.inputs.push(target.querySelector("input[type=radio]"));
    });
    this.boundSubmitListener = this.submit.bind(this);
    document.addEventListener("update-fulfillment-status", this.boundSubmitListener);
  }

  disconnect() {
    document.removeEventListener("update-fulfillment-status", this.boundSubmitListener);
  }

  inksoftStoreActionButtonOutletConnected() {
    this.updateButton();
  }

  filterClick() {
    this.updateButton();
  }

  updateButton() {
    const filter = this.selectedFilter();

    if (this.hasInksoftStoreActionButtonOutlet) {
      this.inksoftStoreActionButtonOutlet.update(filter);
    }

    switch (filter) {
      case "ready_to_fulfill":
        this.undoInputTarget.value = "true";
        break;
      case "in_production":
        this.undoInputTarget.value = "false";
        break;
      default:
        break;
    }
  }

  selectedFilter() {
    const selected = this.inputs.find((target) => target.checked);

    return selected?.value ?? "new";
  }

  async submit() {
    if (this.selectedFilter() !== "new") {
      await this.submitMarkReadyRequest();
      this.element.requestSubmit();
    }
  }

  async submitMarkReadyRequest() {
    const formData = new FormData(this.element);
    const response = await fetch(this.markReadyUrlValue, {
      body: formData,
      method: "POST",
      headers: {
        "X-CSRF-Token": document.head.querySelector("meta[name='csrf-token']")?.content,
      },
    });

    if (response.ok) {
      const body = await response.json();
      window.displayFlash("success", body.message);
    } else {
      try {
        const error = await response.json();
        window.displayFlash("notice", error.message);
      } catch {
        window.displayFlash("notice", "An error occurred. Please try again.");
      }
    }
    this.dispatch("fulfillment-status-updated", { prefix: false });
  }
}
