/* eslint-env browser */
/* global grecaptcha */
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tokenInput", "submitButton"];

  static values = {
    submitText: { type: String, default: "Creating Your Account..." },
  };

  connect() {
    window.recaptchaCallback = () => {
      this.submitButtonTarget.setAttribute("disabled", "");
      this.submitButtonTarget.innerHTML = this.submitTextValue;
      this.element.submit();
    };
  }

  submitButtonTargetConnected() {
    this.submitButtonTarget.setAttribute("data-action", "click->recaptcha#submit:prevent");
  }

  tokenInputTargetConnected() {
    this.tokenInputTarget.setAttribute("data-callback", "recaptchaCallback");
  }

  submit() {
    if (this.element.checkValidity()) {
      grecaptcha.execute();
    } else {
      // Form is invalid, force it to display error messages to the user
      this.element.requestSubmit();
    }
  }
}
