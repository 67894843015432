/* eslint-env browser */
/* eslint-disable no-underscore-dangle */

import { Controller } from "stimulus";
import { useMutation } from "stimulus-use";
import AutoNumeric from "autonumeric";

export default class extends Controller {
  static outlets = ["statements--editor"];

  static targets = ["checkedStatementOrders", "orderToOverwriteTemplate", "orderToAddTemplate"];

  connect() {
    useMutation(this, { element: this.statementsEditorOutlet.element, attributes: true });
  }

  mutate() {
    this.update();
  }

  checkedStatementOrdersTargetConnected() {
    this.update();
  }

  update() {
    this.checkedStatementOrdersTarget.innerHTML = "";

    this.statementsEditorOutlet.selectedOrders.forEach((order) => {
      const template = order.outstanding ? this.orderToOverwriteTemplateTarget : this.orderToAddTemplateTarget;

      this.checkedStatementOrdersTarget.innerHTML += template.innerHTML
        .replaceAll("{{order_id}}", order.order_id)
        .replaceAll("{{order_visual_id}}", order.order_visual_id)
        .replaceAll("{{order_amount}}", order.amount)
        .replaceAll("{{order_unpaid}}", order.outstanding);
    });

    this.moneyElements.forEach((element) => {
      if (!AutoNumeric.isManagedByAutoNumeric(element)) {
        // eslint-disable-next-line no-new
        new AutoNumeric(element, { currencySymbol: this.statementsEditorOutlet.currencySymbolValue });
      }
    });
  }

  get moneyElements() {
    return this.checkedStatementOrdersTarget.querySelectorAll("[data-autonumeric]");
  }
}
