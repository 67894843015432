/* globals HTMLInputElement */

import { Controller } from "stimulus";
import useEventController from "../composables/use_event_controller";

const isNumeric = (n) => !Number.isNaN(Number(n));

export default class extends Controller {
  static targets = ["attempt", "indicator"];

  connect() {
    useEventController(this);

    const handleBeforeInput = this.handleBeforeInput.bind(this);
    const handleInput = this.handleInput.bind(this);
    const handleKeyDown = this.handleKeyDown.bind(this);
    const handlePaste = this.handlePaste.bind(this);

    this.element.querySelector("[data-component='passcode_digit']:first-child")?.focus();

    this.element.querySelectorAll("[data-component='passcode_digit']").forEach((input) => {
      this.listenOn(input, "paste", handlePaste);
      this.listenOn(input, "beforeinput", handleBeforeInput);
      this.listenOn(input, "keydown", handleKeyDown);
      this.listenOn(input, "input", handleInput);
      this.listenOn(input, "click", () => input.select());
    });
  }

  handlePaste(event) {
    const pastedData = event.clipboardData.getData("text/plain");

    if (!isNumeric(pastedData)) {
      return;
    }

    pastedData.split("").forEach((digit, index) => {
      const input = this.element.querySelector(`[data-component="passcode_digit"]:nth-child(${index + 1})`);

      if (!input) {
        return;
      }

      input.focus();
      input.select();

      input.value = digit;
    });

    this.updatePasscodeAttempt();
  }

  handleBeforeInput(event) {
    if (!isNumeric(event.data)) {
      event.preventDefault();
    }
  }

  handleInput(event) {
    const next = event.target.nextElementSibling;

    if (next instanceof HTMLInputElement) {
      next.focus();
      next.select();
    }

    this.updatePasscodeAttempt();
  }

  handleKeyDown(event) {
    if (event.key === "Backspace") {
      event.target.value = "";
      this.selectPreviousDigit(event);
    }

    if (event.key === "ArrowLeft") {
      this.selectPreviousDigit(event);
    }

    if (event.key === "ArrowRight") {
      this.selectNextDigit(event);
    }

    if (event.composed && event.key === "v") {
      return;
    }

    if (!isNumeric(event.key)) {
      event.preventDefault();
    }
  }

  selectNextDigit(event) {
    event.target.nextElementSibling?.focus();
    event.target.nextElementSibling?.select();
  }

  selectPreviousDigit(event) {
    event.target.previousElementSibling?.focus();
    event.target.previousElementSibling?.select();
  }

  updatePasscodeAttempt() {
    const digits = Array.from(this.element.querySelectorAll("[data-component='passcode_digit']"));

    this.attemptTarget.value = digits.map((digit) => digit.value).join("");

    if (digits.filter((digit) => digit.value === "").length === 0) {
      this.indicatorTarget.setAttribute("aria-hidden", false);

      digits.forEach((digit) => {
        digit.disabled = true;
      });

      this.element.submit();
    }
  }
}
