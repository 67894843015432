/* global document, window */

import { Controller } from "stimulus";
import { useMutation } from "stimulus-use";
import useEventController from "../composables/use_event_controller";

export default class extends Controller {
  static values = {
    message: {
      type: String,
      default: "",
    },

    dirty: {
      type: Boolean,
      default: false,
    },
  };

  connect() {
    useEventController(this);
    useMutation(this, { childList: true, subtree: true });

    this.initialValues = {};
    this.fields = [...this.element.elements, ...this.element.querySelectorAll("trix-editor")];
    this.boundHandleBrowserNavigation = this.handleBrowserNavigation.bind(this);

    setTimeout(() => {
      this.setupFields();
      this.setFormHandlers();
      this.element.dataset.initialized = true;
    }, 1000);
  }

  mutate(entries) {
    if (this.element.dataset.initialized !== "true") {
      return;
    }

    entries
      .filter((mutation) => mutation.type === "childList")
      .forEach((mutation) => {
        const allNodes = Array.from([...mutation.removedNodes, ...mutation.addedNodes]);

        if (!allNodes.length) {
          return;
        }

        if (allNodes.filter((node) => node?.type === "hidden" || node?.dataset?.disableWith).length) {
          return;
        }

        if (allNodes.filter((node) => (node?.classList || "").toString().includes("tooltip")).length) {
          return;
        }

        this.dirtyValue = true;
      });
  }

  setupFields() {
    const checkValue = this.checkValue.bind(this);

    this.fields.forEach((field) => {
      if (!field.name || ["submit", "button", "hidden"].includes(field.type)) {
        return;
      }

      this.initialValues[field.name] = field.value;

      if (field.nodeName === "TRIX-EDITOR") {
        field.addEventListener("trix-change", checkValue);
      } else {
        field.addEventListener("change", checkValue);
        field.addEventListener("input", checkValue);
      }
    });
  }

  setFormHandlers() {
    const handleSubmit = this.handleSubmit.bind(this);
    const handleNavigation = this.handleNavigation.bind(this);

    this.listenOn(this.element, "dirty-form:touch", this.touchForm.bind(this));
    this.listenOn(this.element, "dirty-form:submit", handleSubmit);
    this.listenOn(this.element, "submit", handleSubmit);

    document.querySelectorAll("a[href]:not([href^='javascript:']):not([href^='#'])").forEach((element) => {
      if (this.element.contains(element)) {
        return;
      }

      this.listenOn(element, "click", handleNavigation);
    });
  }

  checkValue(event) {
    const field = event.target;

    if (this.initialValues[field.name] !== field.value) {
      this.dirtyValue = true;
    }
  }

  handleBrowserNavigation(event) {
    event.preventDefault();

    if (this.messageValue) {
      event.returnValue = this.messageValue;
    }

    return event.returnValue;
  }

  handleNavigation(event) {
    if (!this.dirtyValue) {
      return;
    }

    event.preventDefault();

    this.dispatch("navigation-click", { target: event.target });
  }

  handleSubmit() {
    window.removeEventListener("beforeunload", this.boundHandleBrowserNavigation);
    this.dirtyValue = false;
    this.element.dataset.initialized = false;
  }

  touchForm() {
    this.dirtyValue = true;
  }

  dirtyValueChanged(value) {
    if (value && this.element.dataset.initialized === "true") {
      window.addEventListener("beforeunload", this.boundHandleBrowserNavigation);
    } else {
      window.removeEventListener("beforeunload", this.boundHandleBrowserNavigation);
    }
  }
}
